<template>
  <div class="setting_form p-4 clear">
    <div class="white_box">
      <!--Body-->
      <div class="body">
        <div class="body-title mt-1">
          <h6>Email Settings</h6>
          <p class="mt-2">You can use your own SMTP server to send reports and other emails.</p>
        </div>
        <div class="body-section mt-2">
          <div class="notification_setting pt-2 pb-3 align-items-center">
            <div class="setting_list">
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6">
                  <div class="input_field">
                    <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.from_email}"
                         class=" input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">From email:</div>
                        <div class="name">
                          <div class="validation_input">
                            <input :disabled="!isEditMode && getFieldEditStatus()" v-model="getWhiteLabel.from_email"
                                   placeholder="Enter your email" maxlength="150"
                                   type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="getWhiteLabelValidations.email_validate.from_email"
                          class="input-error">{{ whiteLabelMessage.from_email }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input_field">
                    <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.email_sender_name}"
                         class=" input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">Email sender name:</div>
                        <div class="name">
                          <div class="validation_input">
                            <input :disabled="!isEditMode && getFieldEditStatus()"
                                   v-model="getWhiteLabel.email_sender_name" placeholder="Email sender name"
                                   maxlength="150"
                                   type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="getWhiteLabelValidations.email_validate.email_sender_name"
                          class="input-error">{{ whiteLabelMessage.email_sender_name }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input_field mt-3">
                    <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.smtp_host}"
                         class=" input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">SMTP host:</div>
                        <div class="name">
                          <div class="validation_input">
                            <input :disabled="!isEditMode && getFieldEditStatus()" v-model="getWhiteLabel.smtp_host"
                                   placeholder="Enter your SMTP host" maxlength="50"
                                   type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="getWhiteLabelValidations.email_validate.smtp_host"
                          class="input-error">{{ whiteLabelMessage.smtp_host }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input_field mt-3">
                    <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.smtp_port}"
                         class=" input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">SMTP port:</div>
                        <div class="name">
                          <div class="validation_input">
                            <input :disabled="!isEditMode && getFieldEditStatus()" v-model="getWhiteLabel.smtp_port"
                                   placeholder="Enter your SMTP port" maxlength="50"
                                   type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="getWhiteLabelValidations.email_validate.smtp_port"
                          class="input-error">{{ whiteLabelMessage.smtp_port }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input_field mt-3">
                    <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.smtp_username}"
                         class=" input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">SMTP username:</div>
                        <div class="name">
                          <div class="validation_input">
                            <input :disabled="!isEditMode && getFieldEditStatus()" v-model="getWhiteLabel.smtp_username"
                                   placeholder="Enter your SMTP username"
                                   maxlength="150" type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="getWhiteLabelValidations.email_validate.smtp_username"
                          class="input-error">{{ whiteLabelMessage.smtp_username }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input_field mt-3">
                    <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.smtp_password}"
                         class=" input_icon_text d-flex align-items-center profile__box">
                      <div class="content">
                        <div class="url">SMTP password:</div>
                        <div class="name">
                          <div class="validation_input">
                            <input :disabled="!isEditMode && getFieldEditStatus()" v-model="getWhiteLabel.smtp_password"
                                   placeholder="Enter your SMTP password"
                                   maxlength="150" type="password">
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="getWhiteLabelValidations.email_validate.smtp_password"
                          class="input-error">{{ whiteLabelMessage.smtp_password }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div :class="{'input-field-error' : getWhiteLabelValidations.email_validate.smtp_encryption}" class="mt-3 mb-2 profile__box input_icon_text align-items-center">
                    <div class="w-100 content">
                      <div class="url">Encryption</div>
                      <div class="name">
                        <div class="validation_input">
                          <b-dropdown right :disabled="!isEditMode && getFieldEditStatus()"
                                      class="mt-2 dropdown-menu-right default_style_dropdown "
                                      :no-caret="true"
                                      ref="bio_domains_dropdown_menu">
                            <div
                              class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
                              slot="button-content">
                              <template v-if="!getWhiteLabel.smtp_encryption">
                                <span class="text">Select SMTP encryption</span>
                              </template>
                              <template v-else>
                                <span class="text text-uppercase">{{ getWhiteLabel.smtp_encryption }}</span>
                              </template>
                              <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
                            </div>
                            <ul class="inner ">

                              <template v-if="encryptionList.length > 0"
                                        v-for="item in encryptionList">

                                <li @click.prevent="selectEncryption(item)" class="list_item_li">
                                  <div class="d-flex align-items-center profile__box">
                                    <div class="content">
                                      <div class="name text-uppercase">
                                        {{ item }}
                                      </div>

                                    </div>
                                  </div>

                                </li>

                              </template>
                            </ul>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span v-if="getWhiteLabelValidations.email_validate.smtp_encryption"
                        class="input-error">{{ whiteLabelMessage.smtp_encryption }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 pl-0 pr-0">
              <div class="alert p-3" role="alert">
                <p><small class="font-weight-bold"><i class="fal mr-1 fa-lightbulb"></i> Tips to connect your email
                  account.</small></p>
                <div>
                  <p class="mt-2"><small class="font-weight-bold">SMTP:</small></p>
                  <ul>
                    <li class="flex align-items-baseline"><span> - </span><small>Ensure that your email account is
                      enabled for SMTP.</small></li>
                    <li class="flex align-items-baseline"><span> - </span><small>Ensure that 2FA is not enabled on your
                      email account.</small></li>
                    <li class="flex align-items-baseline"><span> - </span><small>If 2FA is enabled, please use app
                      password instead of email password.</small></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="check_domain_section p-1 d-inline-flex align-items-center">
            <div v-if="!getWhiteLabel.is_email_connected" class="d-flex align-items-center">
              <span class="campaign_status ml-2 mt-1"></span>
              <p class="ml-2 flex align-items-center">Email verification pending.
                <a class="flex ml-1" v-if="!getWhiteLabelLoaders.email" @click="verifyEmail()">Click here to verify</a>
                <clip-loader v-else class="icon ml-2" style="right: 30px;" :size="'14px'"
                             :color="'#3988fe'"></clip-loader>
              </p>
            </div>
            <div v-else class="domain_connected d-inline-flex">
              <p class="ml-2"><i class="fal font-weight-bold fa-check"></i> Your email connection verified successfully.</p>
            </div>
            <i @click="edit()" class="fal ml-2 fa-pencil"></i>
            <i @click="deleteEmailSettings()" class="fal ml-2 fa-trash"></i>
          </div>
          <hr>
          <button type="button" @click="fetchWhiteLabel(), clear()" class="mr-2 btn---cta light-blue btn-round  btn-bold"><span>Discard changes</span>
          </button>
          <button :disabled="getWhiteLabelLoaders.email" type="button" @click="applyWhiteLabelChanges()"
                  class="btn---cta d-inline-flex  btn-blue with-shadow btn-round   btn-bold">
            <!----><span>Apply changes</span>
            <clip-loader v-if="getWhiteLabelLoaders.store" class="icon ml-2" style="right: 30px;" :size="'12px'"
                         :color="'white'"></clip-loader>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {whiteLabelMessage} from "@/common/attributes";

export default {
  name: 'WhitelabelDomainSettings',
  data () {
    return {
      encryptionList: [
        'ssl',
        'tls'
      ],
      whiteLabelMessage,
      isEditMode: false
    }
  },
  async created () {
    this.isEditMode = this.getWhiteLabel._id ? false : true
  },
  computed: {
    ...mapGetters(['getWhiteLabel', 'getWhiteLabelValidations', 'getWhiteLabelLoaders'])
  },
  methods: {
    ...mapActions(['fetchWhiteLabel', 'verifyWhitelabelEmail']),
    /**
     * check field edit status
     * @returns {this is string[]}
     */
    getFieldEditStatus () {
      return Object.keys(this.getWhiteLabelValidations.email_validate).every(k => this.getWhiteLabelValidations.email_validate[k] === false)
    },
    selectEncryption (item) {
      this.getWhiteLabel.smtp_encryption = item
      this.$refs.bio_domains_dropdown_menu.visible = false
    },
    edit () {
      this.isEditMode = true
      this.getWhiteLabel.is_email_connected = false
    },
    deleteEmailSettings () {
      this.isEditMode = true
      this.getWhiteLabel.is_email_connected = false
      this.getWhiteLabel.from_email = ''
      this.getWhiteLabel.email_sender_name = ''
      this.getWhiteLabel.smtp_host = ''
      this.getWhiteLabel.smtp_port = ''
      this.getWhiteLabel.smtp_username = ''
      this.getWhiteLabel.smtp_password = ''
      this.getWhiteLabel.smtp_encryption = ''
      this.getWhiteLabel.whitelabel_setup = false
    },
    verifyEmail () {
      this.isEditMode = false
      this.verifyWhitelabelEmail()
    },
    clear() {
      this.isEditMode = false
    }
  },
  watch: {
    'getWhiteLabel.from_email' (value) {
      this.whiteLabelCommonEmailValidation('from_email')
    },
    'getWhiteLabel.smtp_host' (value) {
      this.whiteLabelCommonEmailValidation('smtp_host')
    },
    'getWhiteLabel.smtp_port' (value) {
      this.whiteLabelCommonEmailValidation('smtp_port')
    },
    'getWhiteLabel.smtp_username' (value) {
      this.whiteLabelCommonEmailValidation('smtp_username')
    },
    'getWhiteLabel.smtp_password' (value) {
      this.whiteLabelCommonEmailValidation('smtp_password')
    },
    'getWhiteLabel.smtp_encryption' (value) {
      this.whiteLabelCommonEmailValidation('smtp_encryption')
    },
    'getWhiteLabel.email_sender_name' (value) {
      this.whiteLabelCommonEmailValidation('email_sender_name')
    }
  }
}
</script>
<style scoped lang="less">
.setting_form {
  border-left: 1px solid #d0d6e5;
  min-height: 132px;

  .white_box {
    .box_inner {
      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }

          p {
            font-size: small;
          }
        }

        .body-section {
          .alert {
            background: rgba(57, 136, 254, 0.1);

            ul {
              li {
                span {
                  width: 10px;
                }
              }
            }
          }
        }

        a {
          color: #1d8bfa;

          &:hover {
            text-decoration: underline;
          }
        }

        .check_domain_section {
          .campaign_status {
            background: rgb(255, 193, 7);
            box-shadow: rgb(240 205 8 / 30%) 0px 5px 7px 0px;
            height: 12px;
            width: 12px;
            border-radius: 100%;
            display: inline-block;
            margin: 0px auto;
          }

          p {
            color: #856404;

            a {
              cursor: pointer;
              color: #856404;
              text-decoration: underline;
            }

          }

          .fa-pencil, .fa-trash {
            border: 1px solid lightgray;
            border-radius: 5px;
            color: lightgray;
            cursor: pointer;
          }

          .fa-pencil {
            padding: 6px;
            color: #3988FE;
            border-color: #3988FE;
          }

          .fa-trash {
            padding: 6px 8px;
            color: #f2405d;
            border-color: #f2405d;
          }

          .fa-pencil:hover {
            color: white;
            background: #3988FE;
          }

          .fa-trash:hover {
            color: white;
            background: #f2405d;
          }

          .domain_connected {
            p {
              color: #00ad6c;
            }
          }
        }
      }
    }
  }
}
</style>
